// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  project: 'lamarca-site',
  version: '0.0.4',
  breakpoint: 768,
  transition: {
    animation: 'opacity',
    loader: true
  },
  data: {
    url: 'https://storage.googleapis.com/lamarca-d3987.appspot.com/customers/lamarca/pages/versions',
    fallback: '/data'
  },
  firebase: {
    apiKey: 'AIzaSyC73tYoYZbRlkk5fRLscSQl_58UsZY4D54',
    authDomain: 'lamarca-d3987.firebaseapp.com',
    projectId: 'lamarca-d3987',
    storageBucket: 'lamarca-d3987.appspot.com',
    messagingSenderId: '630065604271',
    appId: '1:630065604271:web:b82a8d658feb6feed9965a',
    measurementId: 'G-13V1KFWJDG'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
