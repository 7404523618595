import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GtagModule } from 'angular-gtag';

import { AppComponent } from './app.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([{
      path: '',
      loadChildren: () => import('@lamarca/project/pages/pages.module').then(m => m.PagesModule)
    }], { relativeLinkResolution: 'legacy' }),
    // RouterModule.forRoot([{ path: '', loadChildren: loadAccordingWidth }], { relativeLinkResolution: 'legacy' }),
    GtagModule.forRoot({ trackingId: 'AW-10817877740', trackPageviews: true })
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
}
